/** @format */
import { DurationConst } from '../../../utlis/constants/PlansConstants';
import PriceCard from './PriceCard';
import { useLocation } from 'react-router-dom';
import { Divider } from 'antd';

export default function Plans() {
    const location = useLocation();

    return (
        <>
            {location.pathname === '/' && (
                <h1 className="text text5">
                    выберите <br /> свой удобный план
                </h1>
            )}
            <div className="price_container">
                <PriceCard duration={DurationConst.MONTH_1} />
                <PriceCard duration={DurationConst.MONTH_6} />
                <PriceCard duration={DurationConst.MONTH_12} />
                {/*<Divider*/}
                {/*    className="price_divider_vertical"*/}
                {/*    type="vertical"*/}
                {/*    style={{*/}
                {/*        borderColor: 'black',*/}
                {/*        height: '250px',*/}
                {/*        borderWidth: '3px',*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<div*/}
                {/*    className="price_divider_horizontal"*/}
                {/*    style={{*/}
                {/*        borderColor: 'black',*/}
                {/*        borderWidth: '3px',*/}
                {/*    }}*/}
                {/*/>*/}
                {/*<PriceCard duration={DurationConst.CHARTS_12} />*/}
            </div>
        </>
    );
}
